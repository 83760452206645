<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp" :pa-3="$vuetify.breakpoint.xsOnly">
      <v-flex xs12 max-width-1400 full-width ma-auto px-2 mb-5>
        <h1
          class="primary--text graphik-bold mb-2 mt-0"
          :class="{'font-35': $vuetify.breakpoint.smAndUp, 'font-25': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('companies_title') }}</h1>
        <p
          class="dark-grey--text graphik-light mb-5"
          :class="{'font-20': $vuetify.breakpoint.smAndUp, 'font-15': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('companies_description') }}</p>

        <v-container fluid ma-0 pa-0 grid-list-xl>
          <v-layout v-for="(step, index) in steps" :key="index" :wrap="$vuetify.breakpoint.xsOnly">
            <v-flex sm4 md3 lg2>
              <v-card flat tile color="light-background">
                <v-img
                  width="180"
                  max-height="100"
                  contain
                  :class="{'ma-auto': $vuetify.breakpoint.smAndUp}"
                  :src="step.image"
                  :lazy-src="step.image"
                ></v-img>
              </v-card>
            </v-flex>

            <v-flex sm10 md9 lg10>
              <v-card flat tile color="light-background" height="100%">
                <v-card-text :class="{'px-0': $vuetify.breakpoint.xsOnly}">
                  <p class="dark-grey--text graphik-light font-15 mb-0">{{ step.text }}</p>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Companies',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_COMPANIES,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_COMPANIES
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_COMPANIES
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_COMPANIES
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_COMPANIES
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_COMPANIES
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/aseguradoras'
      },
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/aseguradoras'
      }
    ]
  },
  data () {
    return {
      steps: [
        {
          image: require('@/assets/img/companies/qualitas-full.png'),
          text: this.$ml.get('companies_qualitas_description')
        },
        {
          image: require('@/assets/img/companies/gnp-full.png'),
          text: this.$ml.get('companies_gnp_description')
        },
        {
          image: require('@/assets/img/companies/chubb-full.png'),
          text: this.$ml.get('companies_chubb_description')
        },
        {
          image: require('@/assets/img/companies/hdi-full.png'),
          text: this.$ml.get('companies_hdi_description')
        },
        {
          image: require('@/assets/img/companies/ana-full.png'),
          text: this.$ml.get('companies_ana_description')
        },
        {
          image: require('@/assets/img/companies/afirme-full.png'),
          text: this.$ml.get('companies_afirme_description')
        },
        {
          image: require('@/assets/img/companies/mapfre-full.png'),
          text: this.$ml.get('companies_mapfre_description')
        },
        {
          image: require('@/assets/img/companies/primeroSeguros-full.png'),
          text: this.$ml.get('companies_primeroSeguros_description')
        },
        {
          image: require('@/assets/img/companies/axa-full.png'),
          text: this.$ml.get('companies_axa_description')
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>
